export const FIREBASE_CONFIG = {
  apiKey: "AIzaSyCPm7wzUo40TF9gkYb2TDl3PmcTBqvqmh0",
  authDomain: "parthraghav-com.firebaseapp.com",
  databaseURL: "https://parthraghav-com.firebaseio.com",
  projectId: "parthraghav-com",
  storageBucket: "parthraghav-com.appspot.com",
  messagingSenderId: "493531434974",
  appId: "1:493531434974:web:b5afa15af7d04647f2d74f",
  measurementId: "G-WFZ05XK49G",
};
